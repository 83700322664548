import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();
    return (
        <Container>
            <Row>
                <Col>
                    <h1 className="text-center">Ça s'bastonne !</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2 className="text-center">Bienvenue dans l'arène</h2>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xs="2">
                    <Button
                        className="w-100 p-3"
                        onClick={() => navigate("/create")}
                    >
                        Créer un tournoi
                    </Button>
                </Col>
                <Col xs="2">
                    <Button
                        className="w-100 p-3"
                        onClick={() => navigate("/join")}
                    >
                        Rejoindre un tournoi
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default Home;
