import React, { useState } from "react";
import "./App.css";

import { Routes, Route } from "react-router-dom";

import { TournoiProvider, TournoiType } from "./context/TournoiContext";

import Home from "./layout/Home";
import Create from "./layout/Create";
import Join from "./layout/Join";

function App() {
    const [tournois, setTournois] = useState<TournoiType[]>([]);

    return (
        <TournoiProvider
            value={{
                tournois,
                setTournois: (tournoi) => setTournois(tournoi),
            }}
        >
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/create" element={<Create />} />
                <Route path="/join" element={<Join />} />
            </Routes>
        </TournoiProvider>
    );
}

export default App;
