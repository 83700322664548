import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Table, Container, Row, Col } from "react-bootstrap";
import TournoiContext from "../context/TournoiContext";

const Join = () => {
    const { tournois, setTournois } = useContext(TournoiContext);

    const navigate = useNavigate();

    const addMember = (index: number) => {
        tournois[index].members++;
        setTournois([...tournois]);
    };

    return (
        <Container>
            <Row className="mb-3">
                <Col>
                    <Button onClick={() => navigate("/")}>
                        Retour au menu
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Date</th>
                                <th>Heure</th>
                                <th>Localisation</th>
                                <th>Coût</th>
                                <th>Format</th>
                                <th>Places restantes</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tournois.map((tournoi, index) => (
                                <tr key={index}>
                                    <td>{tournoi.name}</td>
                                    <td>{tournoi.date}</td>
                                    <td>{tournoi.hour}</td>
                                    <td>{tournoi.localisation}</td>
                                    <td>{tournoi.cost}</td>
                                    <td>{tournoi.format}</td>
                                    <td>{tournoi.places - tournoi.members}</td>
                                    <td>
                                        <Button
                                            onClick={() => addMember(index)}
                                        >
                                            Rejoindre
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default Join;
