import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    FormLabel,
    FormControl,
    FormSelect,
    Image,
    Button,
} from "react-bootstrap";
import bracket from "../assets/img/bracket.png";

import TournoiContext from "../context/TournoiContext";

const Create = () => {
    const { tournois, setTournois } = useContext(TournoiContext);
    const navigate = useNavigate();

    const [percent, setPercent] = useState(0.5);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const elements = e.target.elements;
        setTournois(
            tournois.concat({
                name: elements.name.value,
                localisation: elements.localisation.value,
                date: elements.date.value,
                hour: elements.hour.value,
                cost: elements.cost.value,
                format: elements.format.value,
                places: elements.places.value || 24,
                members: 0,
            })
        );
        navigate("/join");
    };
    return (
        <Container className="h-100">
            <Row className="mb-3">
                <Col>
                    <Button onClick={() => navigate("/")}>
                        Retour au menu
                    </Button>
                </Col>
            </Row>
            <Row className="h-100">
                <Col
                    as={Form}
                    className="d-flex flex-column justify-content-center"
                    onSubmit={handleSubmit}
                >
                    <Row>
                        <Col>
                            <FormGroup controlId="name">
                                <FormLabel>Nom</FormLabel>
                                <FormControl required />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup controlId="localisation">
                                <FormLabel>Localisation</FormLabel>
                                <FormControl required />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup controlId="date">
                                <FormLabel>Date</FormLabel>
                                <FormControl type="date" required />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup controlId="hour">
                                <FormLabel>Horaire</FormLabel>
                                <FormControl type="time" required />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup controlId="places">
                                <FormLabel>Nombre de places</FormLabel>
                                <FormControl
                                    type="number"
                                    min="24"
                                    defaultValue={24}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup controlId="cost">
                                <FormLabel>
                                    Coût d'inscription par joueur
                                </FormLabel>
                                <FormControl
                                    type="number"
                                    min="5"
                                    step=".5"
                                    defaultValue={5}
                                    required
                                    onChange={(e) => {
                                        const value = Number(e.target.value);
                                        if (value < 5) {
                                            setPercent(0.5);
                                        } else {
                                            setPercent(value / 10);
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup controlId="places">
                                <FormLabel>Part de ça s'bastonne</FormLabel>
                                <FormControl
                                    type="number"
                                    readOnly
                                    value={percent}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup controlId="format">
                                <FormLabel>Format</FormLabel>
                                <FormSelect required disabled>
                                    <option>Best Of 3</option>
                                </FormSelect>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                type="submit"
                                className="d-block ms-auto mt-3"
                            >
                                Créer
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col className="d-flex align-items-center">
                    <Image src={bracket} fluid />
                </Col>
            </Row>
        </Container>
    );
};

export default Create;
