import React from "react";

export type TournoiType = {
    name: string;
    localisation: string;
    date: string;
    hour: string;
    places: number;
    cost: string;
    format: string;
    members: number;
};

const TournoiContext = React.createContext<{
    tournois: TournoiType[];
    setTournois: (tournois: TournoiType[]) => void;
}>({
    tournois: [],
    setTournois(tournois: TournoiType[]) {},
});
export const TournoiProvider = TournoiContext.Provider;
export default TournoiContext;
